import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Box, HStack, Text, useBreakpointValue, Link as ChakraLink, Skeleton } from '@chakra-ui/react'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { alignmentStyles, boxStyles, textStyles } from './utils'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../carousel'

export interface categoryProps {
  href: string
  imageUrl: string
  name: string
}

export interface categorySliderProps {
  isLoading?: boolean
  displayImages?: boolean
  categoryList?: Array<categoryProps>
}

export const LocalSlider = ({ isLoading, displayImages = true, categoryList }: categorySliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [showSlider, setShowSlider] = useState(false)

  const isMobile = useBreakpointValue({ base: true, md: false })
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false })
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const getPerView = () => {
    if (isDesktop) return 6
    if (isTablet) return 4
    return 2
  }

  const perView = getPerView()
  const drag = useBreakpointValue({ base: true, md: false })
  const breakpoints = {
    '(min-width: 48em)': { slides: { origin: 0, perView: 4, spacing: 16 } },
    '(min-width: 62em)': { slides: { origin: 0, perView: 6, spacing: 16 } },
    '(min-width: 80em)': { slides: { origin: 0, perView: 6, spacing: 16 } },
  }

  const imgSize = useBreakpointValue({ base: 167, md: 200 })

  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: false,
    mode: 'free',
    renderMode: 'precision',
    rubberband: true,
    drag,
    selector: '.chakra-carousel__slide',
    slides: { origin: 0, perView, spacing: 16 },
    breakpoints,
  })

  useEffect(() => {
    const isSlidable = categoryList?.length > (isMobile ? 2 : isTablet ? 4 : 6)
    setShowSlider(isSlidable)
  }, [isMobile, isTablet, isDesktop, categoryList])

  if (isLoading) {
    return (
      <Box py={10}>
        <Skeleton h="259px" w="100%" />
      </Box>
    )
  }

  if (!categoryList?.length) {
    return null
  }

  const renderCategory = (category: categoryProps, displayImage: boolean) => {
    const renderCart = () => (
      <Box
        as={category.href ? 'a' : 'div'}
        display={'flex'}
        alignContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        flexDir={'column'}
        {...boxStyles[displayImage.toString()]}
        _hover={{
          textDecoration: 'underline',
          textColor: 'black',
        }}
      >
        {displayImage && (
          <Image
            objectFit={isMobile ? 'contain' : 'cover'}
            src={category.imageUrl}
            alt={category.name}
            width={imgSize}
            height={imgSize}
            sizes="(max-width: 768px) 167px, 200px"
            tabIndex={0}
          />
        )}

        <Text {...textStyles[displayImage.toString()]} tabIndex={-1}>
          {category.name}
        </Text>
      </Box>
    )
    return (
      <CarouselSlide key={category.name}>
        {category.href ? (
          <ChakraLink href={category.href} tabIndex={-1}>
            {renderCart()}
          </ChakraLink>
        ) : (
          renderCart()
        )}
      </CarouselSlide>
    )
  }

  return (
    <Box py={{ base: 0, md: 10 }} maxW="container.2xl" mx="auto" mb={{ base: '26px', md: 0 }} position={'relative'}>
      {showSlider && (
        <Box display="flex" justifyContent="end" mb="26px" hideBelow={'sm'}>
          <CarouselIconButton
            width="48px"
            height="48px"
            background="shading.100"
            borderRadius="4px"
            mr="8px"
            onClick={() => slider.current?.prev()}
            icon={<IoChevronBackOutline />}
            aria-label="Previous Slide"
            position="absolute"
            top={{ base: '35%', md: '40%' }}
            left="0"
            marginLeft="sm"
            zIndex={2}
            disabled={currentSlide === 0}
            style={{ display: currentSlide === 0 ? 'none' : 'flex' }}
          />
          <CarouselIconButton
            width="48px"
            height="48px"
            background="shading.100"
            borderRadius="4px"
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
            position="absolute"
            top={{ base: '35%', md: '40%' }}
            zIndex={2}
            marginRight="sm"
            disabled={currentSlide === categoryList.length - 1}
            style={{ display: currentSlide === categoryList.length - perView ? 'none' : 'flex' }}
          />
        </Box>
      )}

      <Carousel mb="26px" ref={ref} sx={{ ...alignmentStyles['left'] }}>
        {categoryList.map((category) => {
          return renderCategory(category, displayImages)
        })}
      </Carousel>

      {categoryList.length > 1 && showSlider && (
        <HStack position="relative" width="full" justify="center" display={'flex'} gap={0}>
          {categoryList.map((_, idx) => (
            <Box
              flexBasis={'100%'}
              w="0"
              tabIndex={0}
              aria-label={`Go to category ${idx + 1}`}
              cursor="default"
              key={idx}
              height="2px"
              bg={currentSlide === idx ? 'text' : 'shading.300'}
            />
          ))}
        </HStack>
      )}
    </Box>
  )
}
