import { FunctionComponent, memo, useState } from 'react'
import { Accordion, Button, Box, HStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInfiniteHits, UseInfiniteHitsProps } from 'react-instantsearch'
import { useLocale } from 'frontastic/contexts'
import { refItemCount } from './item-count'
import { ALGOLIA_FILTERS } from '../../constants'
import { AlgoliaFilterItem, StarterKitAlgoliaProduct } from '../../types'
import { NumericMenu } from '../filtering/numeric-menu'
import { RefinementList } from '../filtering/refinement-list'
import { Slider } from '../filtering/slider'

type FiltersProps = {
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

export const Filters = ({ infiniteHitsProps }: FiltersProps) => {
  const { country, currency } = useLocale()

  const { formatMessage } = useFormat({ name: 'common' })

  const [expandAll, setExpandAll] = useState(false)

  return (
    <>
      <Box mb={3.5} display={{ base: 'none', md: 'block' }}>
        <HStack justify="space-between">
          <Button
            variant="link"
            size="sm"
            color="shading"
            textDecoration="underline"
            fontWeight="extrabold"
            onClick={() => setExpandAll((prev) => !prev)}
          >
            {expandAll
              ? formatMessage({ id: 'category.filters.action.collapseAll' })
              : formatMessage({ id: 'category.filters.action.expandAll' })}
          </Button>
          <Button
            opacity={0}
            variant="link"
            size="sm"
            color="shading"
            textDecoration="underline"
            onClick={() => refItemCount.current?.focus()}
            _focus={{ opacity: 1 }}
          >
            {formatMessage({ id: 'category.filters.action.skipFilter' })}
          </Button>
        </HStack>
      </Box>
      <FilterList
        expandAll={expandAll}
        infiniteHitsProps={infiniteHitsProps}
        isInternationalUser={country && country !== 'US'}
      />
    </>
  )
}

type FilterListProps = {
  expandAll: boolean
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
  isInternationalUser: boolean
}

const FilterList: FunctionComponent<FilterListProps> = memo(({ expandAll, infiniteHitsProps, isInternationalUser }) => {
  const { results } = useInfiniteHits(infiniteHitsProps)
  const renderingContent = results?.renderingContent

  const getAlgoliaFilters = () => {
    let filtersList = []
    if (renderingContent) {
      const facetOrders: any[] = renderingContent?.facetOrdering?.facets?.order
      filtersList = facetOrders?.map((eleAttribute) => {
        const algoliaFilterObj = ALGOLIA_FILTERS?.filter((obj) => obj.attribute == eleAttribute)
        if (algoliaFilterObj?.length > 0) {
          return algoliaFilterObj?.[0]
        } else {
          return { attribute: '' }
        }
      })
    } else {
      filtersList = ALGOLIA_FILTERS
    }
    return filtersList
  }

  return (
    <>
      {getAlgoliaFilters()?.map(
        (refinement) =>
          refinement?.attribute !== 'categories' && (
            <RefinementItem
              key={refinement.attribute}
              expandAll={expandAll}
              infiniteHitsProps={infiniteHitsProps}
              isInternationalUser={isInternationalUser}
              refinement={refinement}
            />
          ),
      )}
    </>
  )
})
FilterList.displayName = 'FilterList'

type RefinementItemProps = {
  refinement: AlgoliaFilterItem
  expandAll: boolean
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
  isInternationalUser: boolean
}

const RefinementItem: FunctionComponent<RefinementItemProps> = ({
  expandAll,
  infiniteHitsProps,
  isInternationalUser,
  refinement,
}) => {
  const [prevExpandAll, setPrevExpandAll] = useState(expandAll)
  const [isExpanded, setIsExpanded] = useState(expandAll)

  // at first this might seem like a bug, but it's not
  // usually we would want to set the state in the useEffect which would cause two renders
  // but in this case we want to avoid that
  // read more here: https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (prevExpandAll !== expandAll) {
    setPrevExpandAll(expandAll)
    setIsExpanded(expandAll)
  }

  return (
    <Accordion allowMultiple={true} index={isExpanded ? [0] : []} onChange={() => setIsExpanded((prev) => !prev)}>
      <DynamicFilter
        infiniteHitsProps={infiniteHitsProps}
        isInternationalUser={isInternationalUser}
        refinement={refinement}
      />
    </Accordion>
  )
}

type DynamicFilterProps = {
  refinement: AlgoliaFilterItem
  isInternationalUser: boolean
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

const DynamicFilter: FunctionComponent<DynamicFilterProps> = memo(
  ({ isInternationalUser, infiniteHitsProps, refinement }) => {
    if (refinement.type === 'numeric') {
      return <NumericMenu key={refinement.attribute} {...refinement} />
    } else if (refinement.type === 'list') {
      if (refinement.attribute === 'availability.availabilestore') {
        if (isInternationalUser) {
          return null
        } else {
          // Only show Store facet for US users
          return (
            <RefinementList
              key={refinement.attribute}
              {...refinement}
              storePickup={true}
              infiniteHits={infiniteHitsProps}
            />
          )
        }
      } else {
        return <RefinementList key={refinement.attribute} {...refinement} storePickup={false} />
      }
    } else if (refinement.type === 'range') {
      return <Slider key={refinement.attribute} {...refinement} />
    } else return null
  },
)

DynamicFilter.displayName = 'DynamicFilter'
