import { Dispatch, SetStateAction } from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import min from 'lodash/min'
import { Thumbnails } from '../../../types'
import { useGridLayout } from '../../hooks/use-grid-layout'

const SMALL_SIZE = 3
const LARGE_SIZE = 4
export type ProductSwatchSelectorSize = 'small' | 'large'

export interface SwatchSelectorProps {
  thumbnails: Thumbnails[]
  selectedImage: Thumbnails | undefined
  setSelectedImage: Dispatch<SetStateAction<Thumbnails>>
  showText?: boolean
  size?: ProductSwatchSelectorSize
  productSlug: string
}

export const SwatchSelector = ({
  thumbnails,
  selectedImage,
  setSelectedImage,
  showText = true,
  size,
  productSlug,
}: SwatchSelectorProps) => {
  const { currentLayout } = useGridLayout()

  if (!thumbnails || thumbnails.length === 0) return

  const isForthColumn = currentLayout === 'condensed'
  const iterateLimit =
    isForthColumn || size === 'small'
      ? SMALL_SIZE
      : min([size === 'large' ? LARGE_SIZE : SMALL_SIZE, thumbnails.length])

  return (
    <Flex gap={3} alignItems={'center'}>
      {thumbnails?.slice(0, iterateLimit).map((thumb: Thumbnails, i: number) => {
        if (!thumb.swatch_url) return
        return (
          <Tooltip
            key={thumb.id}
            placement="top"
            rounded="md"
            maxW={{ base: '90vw', sm: 'auto' }}
            mr={{ base: 2, sm: 0 }}
            hasArrow
            label={
              <Text textStyle={'body-75'} textTransform={'capitalize'}>
                {thumb?.color_name?.replaceAll('_', ' ').toLocaleLowerCase()}
              </Text>
            }
          >
            <Box
              as="button"
              tabIndex={0}
              w={7}
              h={7}
              borderRadius={'50%'}
              overflow={'hidden'}
              display={'inline-block'}
              cursor={'pointer'}
              boxShadow={
                selectedImage?.id === thumb?.id
                  ? 'rgb(0, 0, 0) 0px 0px 0px 0px, white 0px 0px 0px 2px, rgb(0, 0, 0) 0px 0px 0px 4px'
                  : 'rgb(0, 0, 0) 0px 0px 0px 0px, white 0px 0px 0px 2px, #E1E5E8 0px 0px 0px 4px'
              }
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setSelectedImage(thumb)
                const activeElement = document.activeElement as HTMLElement
                if (activeElement?.blur) {
                  activeElement.blur() // This will remove focus after click
                }
              }}
            >
              <Box w={'100%'} h={'100%'} overflow={'hidden'} display={'flex'}>
                <Image
                  src={thumb?.swatch_url}
                  alt={thumb?.color_name ?? ''}
                  width={28}
                  height={28}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    display: 'block',
                  }}
                />
              </Box>
            </Box>
          </Tooltip>
        )
      })}
      {showText && thumbnails.length > iterateLimit && (
        <NextLink tabIndex={0} href={productSlug}>
          <Text textStyle={'body-75'}>{`+${thumbnails.length - iterateLimit}`}</Text>
        </NextLink>
      )}
    </Flex>
  )
}
