import { memo } from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Skeleton,
} from '@chakra-ui/react'
import { useComposable } from 'composable/components/composable-provider'
import { StructuredDataSchema } from 'composable/components/structured-data-schema'
import { useFormat } from 'helpers/hooks/useFormat'
import { toTitleCase } from '../../utils'

export interface BreadcrumbItem {
  sort_order: number
  node_key: string
  name: string
}

interface BreadcrumbProps {
  isLoading?: boolean
  query: string
  isSearchPage?: boolean
  items?: BreadcrumbItem[]
}

const HOME_NODE_KEY = 'home'

const SearchBreadcrumb = ({ query }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { path } = useComposable()

  return (
    <>
      <StructuredDataSchema
        data={{
          breadcrumbs: [
            {
              label: formatMessage({ id: 'product.breadcrumbs.home' }),
              link: path.getHome(),
            },
            {
              label: formatMessage({ id: 'header.search.title' }),
              link: '/search',
            },
          ],
        }}
        type="plp"
      />
      <ChakraBreadcrumb textStyle={{ base: 'body-75', md: 'body-100' }} color={'text-muted'}>
        <BreadcrumbItem>
          <BreadcrumbLink
            href="/"
            _hover={{
              textStyle: { base: 'link-75', md: 'link-100' },
            }}
          >
            {formatMessage({
              id: 'category.breadcrumb.root',
              values: { query },
            })}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {query && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#" textTransform={'none'}>
              {formatMessage({
                id: 'category.breadcrumb.resultsFor',
                values: { query },
              })}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </ChakraBreadcrumb>
    </>
  )
}

const PlpBreadcrumb = ({ items }) => {
  const { path } = useComposable()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  if (!items || items?.length < 1) return null

  const breadcrumbs = isMobile ? items.filter((cat) => cat.node_key !== HOME_NODE_KEY) : items
  const sortedList = breadcrumbs.sort((a, b) => a.sort_order - b.sort_order)

  return (
    <>
      <StructuredDataSchema
        data={{
          breadcrumbs: (items || []).map((item, index) => ({
            label: item.name,
            link: item.node_key !== 'home' ? path.getPLP(item.node_key) : path.getHome(),
          })),
        }}
        type="plp"
      />
      <ChakraBreadcrumb
        overflowX={isMobile ? 'scroll' : undefined}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        color={'text-muted'}
      >
        {sortedList?.map((item, idx) => {
          const isCurrentPage = idx + 1 === sortedList.length
          return (
            <BreadcrumbItem
              key={item.node_key}
              pointerEvents={isCurrentPage ? 'none' : undefined}
              aria-current={isCurrentPage ? 'page' : undefined}
              textStyle={{ base: 'body-75', md: 'body-100' }}
            >
              <BreadcrumbLink
                tabIndex={0}
                href={item.node_key !== 'home' ? path.getPLP(item.node_key) : path.getHome()}
                whiteSpace={'nowrap'}
                _hover={{
                  textStyle: { base: 'link-75', md: 'link-100' },
                }}
              >
                {toTitleCase(item.name)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        })}
      </ChakraBreadcrumb>
    </>
  )
}

export const Breadcrumb = memo(({ isLoading, query, isSearchPage = false, items }: BreadcrumbProps) => {
  if (isLoading) {
    return <Skeleton h={{ base: '21px', lg: 6 }} />
  }

  return isSearchPage ? <SearchBreadcrumb query={query} /> : <PlpBreadcrumb items={items} />
})

Breadcrumb.displayName = 'Breadcrumb'
